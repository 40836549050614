* {
  -webkit-tap-highlight-color: transparent;
}

.CityMap {
  display: flex;
  height: 55%;
}

.map {
  position: fixed;
  top: 56px;
  bottom: 247px;
  left: 0;
  width: 50%;
  overflow: hidden;
}

.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(180deg, #4863aa 72.92%, #01000E 100%);
  path {
    cursor: pointer;
    &:hover,
    &.active {
      fill: #324370;
    }

    &.noHover {
      pointer-events: none;
      &:hover {
        fill: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .CityMap {
    display: block;
  }

  .map {
    width: 100%;
    top: 55px;
    height: 35%;
    overflow: visible;
  }
}

@media only screen and (max-width: 1024px) and (min-height: 1100px) {
  .map {
    height: 45%;
  }
}
