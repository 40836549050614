.SearchResultsItem {
  position: relative;
  width: 32%;
  height: 40%;
  box-sizing: border-box;
  padding: 10px;
  padding-top: 13px;
  //background-color: #030a1b;
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 1%;
  text-decoration: none;

  &.mobile {
    width: 50%;
    min-height: 248px;
    max-height: 248px;
    margin: 0;
    margin-bottom: 12px;
    padding-top: 0;

    .image {
      width: 100%;
      height: 134px;
      max-height: 134px;
    }

    h1 {
      width: 100%;
      text-align: left;
      color: #c4c4c4 !important;
    }

    .footer {
      flex-direction: row;
      text-transform: uppercase;
      bottom: 4px;

      span {
        color: inherit;
      }
    }
  }

  h1 {
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    margin: 10px 0;
    text-align: left;
    color: #c4c4c4;
  }

  &:hover {
    background-color: #131929;
  }
}

.image {
  width: 100%;
  height: calc(100% - 88px);
  background-image: url(https://source.unsplash.com/random/800x600);
  background-position: center;
  background-size: cover;
  border-radius: 2px;
}

.footer {
  position: absolute;
  bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  height: 20px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  color: #2f4273;
  text-transform: uppercase;

  .views {
    display: flex;

    .viewsIcon {
      display: block;
      width: 13px;
      height: 10px;
      margin-right: 3px;
      background-image: url(../../../../assets/images/views.svg);
      background-position: center -1px;
      background-repeat: no-repeat;
      font-size: 0;
    }
  }
}
