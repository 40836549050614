body {
  overflow: hidden;
}

*,
*:focus,
*:hover {
  outline: none;
}

.LaunchPage {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  background-size: cover;
  background-position: center;
}

.mobileMap {
  display: none;
}

.background {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/images/map.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1.5px);
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 3, 11);
  opacity: 0.9;
  animation: fadeinout 15s linear infinite;
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.4;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  margin-top: 186px;
  text-transform: uppercase;
}

.heading {
  display: flex;
  width: 635px;
  height: 57px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    margin: 0;
    width: 254px;
    font-size: 28px;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: #8998c2;
  }
}

.heading .capitalLetter {
  font-size: 37px;
  font-style: normal;
  font-weight: normal;
  line-height: 46px;
  letter-spacing: 0em;
}

.line {
  width: 182px;
  height: 1px;
  background-color: #7284b6;
}

.releaseDate {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 49px;
  margin-bottom: 15px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #8998c2;
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
  }
}

.arrowIcon {
  display: block;
  width: 17px;
  height: 17px;
  margin-left: 15px;
  font-size: 0;
  background-image: url(../../assets/images/arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  user-select: none;
  transition: transform 0.1s ease-out;

  &.clicked {
    transform: scale(1.2) rotate(90deg) translate(10px);
    margin-bottom: 10px;
  }
}

.releaseDate:hover .arrowIcon,
.arrowIcon:hover {
  transform: scale(1.2);
}

.releaseDate:hover .arrowIcon.clicked,
.arrowIcon.clicked:hover {
  transform: scale(1.2) rotate(90deg) translate(10px);
}

@media only screen and (max-width: 376px) {
  .content {
    margin-top: 120px;
  }

  .overlay {
    position: fixed;
  }

  .releaseDate {
    font-size: 18px;
  }

  .heading {
    width: 100%;
    h1 {
      margin-left: 5px !important;
      margin-right: 5px !important;
      font-size: 24px;
      span {
        font-size: 34px !important;
      }
    }
  }
}

@media only screen and (max-width: 667px) {
  body {
    overflow-y: auto !important;
  }

  .LaunchPage {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;

    .mobileMap {
      width: 100%;
      display: inline-block;
      position: absolute;
    }

    .overlay {
      position: fixed;
      padding-top: 100%;
      box-sizing: content-box;
      animation: fadeinoutMobile 15s linear infinite;
    }

    &.formIsOpen {
      .mobileMap {
        position: fixed;
      }

      .overlay {
        position: fixed;
      }
    }
  }

  .background {
    display: none;
    background-image: url(../../assets/images/map_mobile-svg.svg);
    filter: blur(0);
  }

  .content {
    position: fixed;
  }

  .heading {
    justify-content: center;

    h1 {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .line {
    width: 50px;
    background-color: #7284b6;
  }
}

@keyframes fadeinoutMobile {
  0%,
  100% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
}
