.SearchResultsPopup {
  display: flex;
  position: fixed;
  z-index: -12;
  top: 57px;
  left: 0;
  right: 0;
  bottom: 0;
}

.closeIcon {
  position: absolute;
  top: 2px;
  right: 4px;
  display: block;
  width: 29px;
  height: 27px;
  font-size: 0;
  background-image: url(../../../assets/images/searchClose.svg);
  cursor: pointer;
}

.content {
  position: absolute;
  top: 15px;
  left: 35px;
  right: 35px;
  bottom: 20px;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding-top: 45px;
  padding-left: 50px;
  padding-right: 50px;
  background: #01030b;
  border-radius: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 100px);
  height: 48px;
  border-top: 1px solid rgba(47, 66, 115, 0.5);

  .switchPageButtons {
    display: flex;
    justify-content: space-between;
    width: 70px;
    height: 20px;

    i {
      display: block;
      width: 20px;
      height: 20px;
      font-size: 0;
      cursor: pointer;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .switchNext {
      background-image: url(../../../assets/images/switchNext.svg);
      &.disabled {
        background-image: url(../../../assets/images/switchNextDisabled.svg);
        cursor: auto;
      }
    }
    .switchPrevious {
      background-image: url(../../../assets/images/switchPrevious.svg);
      &.disabled {
        background-image: url(../../../assets/images/switchPreviousDisabled.svg);
        cursor: auto;
      }
    }
  }
}

.noSearchResults {
  align-self: center;
  margin: auto;
  padding-bottom: 48px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
}

.spinner {
  align-self: center;
  margin: auto;
  padding-bottom: 48px;
}

.error {
  align-self: center;
  margin: auto;
  padding-bottom: 48px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
}
