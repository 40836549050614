.ContentContainer {
  position: fixed;

  bottom: 53px;
  width: 100%;
  height: 50%;
  padding-top: 10px;
  background: linear-gradient(
    180deg,
    rgba(1, 3, 9, 0.38) 0%,
    rgba(1, 3, 9, 0) 100%
  );
  border-top: 1px solid rgba(47, 66, 115, 0.5);
  overflow: auto;

  &.hide {
    display: none;
  }
}

.spinner {
  position: fixed;
  bottom: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
}

.listContainer {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  z-index: -1;
  bottom: 53px;
  width: 100%;
  height: 50%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: auto;
  opacity: 0;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    z-index: 1;
  }

  &.communities,
  &.featured {
    background-color: #03091a;
  }
}

.communityContainer {
  width: 50%;
}
