*,
*:focus,
*:hover {
  outline: none;
}

.SubscriptionModal {
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  color: #7284b6;
}

.header,
.body,
.footer {
  background: rgb(42, 53, 82);
  border: none !important;
}

.body {
  text-align: center;
}

.footer {
  display: flex;
  justify-content: center !important;
}

.button {
  width: 170px;
  height: 42px;
  background-color: #00030a;
  color: #7284b6;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

.button:hover {
  background-color: #05153a;
  color: #96aae0;
}
