.EmailForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* width: 635px; */
  width: 100%;
  height: 197px;
  box-sizing: border-box;
  padding-top: 30px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  background: rgba(42, 53, 82, 0.67);
  border-radius: 10px;
  transition: opacity 0.4s ease-out;

  &.hide {
    display: none;
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  &.small {
    height: 255px;
    .text {
      width: 100%;
      font-size: 13px;
    }

    .submitButton {
      width: 100%;
      font-size: 14px;
    }

    form {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      box-sizing: border-box;
      margin-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .emailInput {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &.hide::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .text {
    width: 615px;
    height: 56px;
    box-sizing: border-box;
    margin-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: center;

    color: #7284b6;
  }

  form {
    display: flex;
    justify-content: space-between;
    width: 505px;
  }

  .submitButton {
    width: 176px;
    height: 47px;
    background-color: #00030a;
    color: #7284b6;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: #05153a;
      color: #96aae0;
    }

    &.sent:disabled {
      background-color: #4f576f;
      color: #6b7a9c;
      cursor: auto;
    }
  }

  .emailInput {
    width: 300px;
    height: 25px;
    position: relative;
    bottom: -5px;
    background-color: transparent;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #7284b6;
    outline: none;
    border: none;
    border-bottom: 2px solid #7284b6;

    &::placeholder {
      padding-top: 14px;
      font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: #7284b6;
      opacity: 1;
    }

    &.invalid {
      border-bottom: 2px solid red;
    }
  }
}

/* @media only screen and (max-height: 610px) {
  .EmailForm:not(.small) {
    color: red;
    width: inherit;
    height: 180px !important;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: scroll;
  }
} */

@media only screen and (max-width: 667px) {
  .EmailForm:not(.small) {
    width: 345px;
    height: 305px;
    padding-top: 30px;

    .emailInput {
      width: 100%;
      margin-bottom: 35px;

      &.success {
        text-align: center;
      }

      &::placeholder {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.05em;
      }
    }

    .text {
      width: 90%;
      margin-bottom: 50px;
      padding: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.05em;
      text-align: center;
    }

    .submitButton {
      align-self: center;
    }

    form {
      flex-direction: column;
      justify-content: center;
      width: 90%;
      box-sizing: border-box;
      margin-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
