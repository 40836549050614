.StoryPopup {
  position: fixed;
  z-index: 3;
  top: 10px;
  left: 20px;
  right: 35px;
  bottom: 20px;
  padding-top: 8px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 40px;
  columns: auto 2;
  background: linear-gradient(
    270.31deg,
    #00030b 112.91%,
    rgba(57, 71, 104, 0.182128) 150.05%,
    rgba(70, 86, 125, 0) 150.07%,
    rgba(17, 27, 52, 0.59375) 150.08%
  );

  overflow: hidden;
  column-fill: auto;
  border-radius: 10px;

  .closePopupIcon {
    position: fixed;
    top: 5px;
    right: 5px;
    display: block;
    width: 29px;
    height: 29px;
    font-size: 0;
    background-image: url(../../assets/images/closeIcon.svg);
    cursor: pointer;
  }

  .content {
    width: 100%;
    height: calc(100% - 40px);
    transition: margin-left 0.5s ease-out;

    .text {
      //text-indent: 40px;
      padding-left: 40px;
      padding-right: 40px;
      font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      color: #c4c4c4;

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

      * {
        color: #c4c4c4 !important;
      }
    }

    .header {
      display: block;
      position: relative;
      width: 100%;
      height: 60%;
      background-image: url(https://source.unsplash.com/random/800x600);
      background-size: cover;
      background-position: center;
      border-radius: 5px;
      .heading {
        position: absolute;
        bottom: 0;
        display: flex;
        min-height: 30%;
        width: 100%;
        background: rgba(2, 6, 18, 0.81);
        .author {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 33%;

          span {
            position: absolute;
            bottom: 12px;
            font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #56658a;
          }

          .authorPhoto {
            position: absolute;
            bottom: 40px;
            width: 60%;
            border-radius: 50%;
            background-image: url('https://ofad.org/files/daily-photo/recent-and-random-portraits_8.jpg');
            background-size: cover;
            background-position: center;
            border: 1px solid #56658a;
            &::after {
              content: '';
              display: block;
              padding-bottom: 100%;
            }
          }
        }
        .title {
          position: relative;
          width: 67%;
          h1 {
            margin: 0;
            padding-top: 14px;
            padding-right: 25px;
            padding-bottom: 35px;
            font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #c4c4c4;
          }

          .titleFooter {
            position: absolute;
            bottom: 14px;
            right: 27px;
            display: flex;
            align-items: center;

            span {
              position: relative;
              bottom: -1px;
              display: block;
              font-family: 'Libre Baskerville', 'Times New Roman', Georgia,
                serif;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0em;
              text-align: center;
              color: #56658a;
            }

            i {
              display: block;
              font-size: 0;

              &.share {
                width: 12px;
                height: 15px;
                margin-right: 18px;
                background-image: url(../../assets/images/shareIcon.svg);
                background-size: contain;
                background-repeat: no-repeat;
                cursor: pointer;
              }

              &.views {
                width: 13px;
                height: 10px;
                margin-right: 3px;
                background-image: url(../../assets/images/views-light.svg);
              }
            }
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: calc(100% - 40px);
    height: 40px;
    border-top: 1px solid rgba(47, 66, 115, 0.5);

    .switchPageButtons {
      display: flex;
      justify-content: space-between;
      width: 64px;
      i {
        display: block;
        width: 20px;
        height: 20px;
        font-size: 0;
        background-repeat: no-repeat;
        cursor: pointer;
        &.prevPage {
          background-image: url(../../assets/images/switchPrevious.svg);
          &.disabled {
            background-image: url(../../assets/images/switchPreviousDisabled.svg);
            cursor: auto;
            pointer-events: none;
          }
        }

        &.nextPage {
          background-image: url(../../assets/images/switchNext.svg);
          &.disabled {
            background-image: url(../../assets/images/switchNextDisabled.svg);
            cursor: auto;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.testBox {
  position: absolute;
  top: -100%;
}

.shareButtonBlock {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 39px;
  margin-top: 10px;
  border-top: 1px solid rgba(47, 66, 115, 0.25);
  border-bottom: 1px solid rgba(47, 66, 115, 0.25);
  break-inside: avoid;
  page-break-inside: avoid;

  &.bottom {
    position: relative;
    top: -6px;
    border: none;
  }

  .button {
    display: flex;
    cursor: pointer;
    user-select: none;
  }

  .buttonTitle {
    margin-right: 15px;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #56658a;
  }
  i {
    position: relative;
    bottom: 2px;
    display: block;
    width: 12px;
    height: 15px;
    background-image: url(../../assets/images/shareIcon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 0;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1024px) {
  .StoryPopup {
    display: block !important;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    overflow: auto;
    columns: auto auto !important;
    border: 2px solid #56658a;

    .closePopupIcon {
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 14px;
      width: 30px;
      height: 28px;
      background-image: url(../../assets/images/mobileStoryClose.svg);
    }

    .content {
      display: block !important;
      height: auto;
      .header {
        height: 280px !important;
        .heading {
          flex-direction: row;
          height: auto;
          .author {
            position: relative;
            span {
              white-space: nowrap;
            }
            .authorPhoto {
              position: absolute;
              left: calc(50% - 64px);
              bottom: 40px !important;
              bottom: 100px;
              width: 127px !important;
              height: 127px;
              &::after {
                content: none;
              }
            }
          }
          .title {
            min-height: 92px !important;
            position: static;
            box-sizing: border-box;
            padding-bottom: 0;
            flex-direction: column;
            padding-left: 15px;
            padding-right: 15px;

            .share {
              display: none !important;
            }

            .titleFooter {
              width: 100%;
              right: 15px;
              flex-direction: row;
              justify-content: flex-end;
            }
            h1 {
              box-sizing: border-box;
              padding-right: 0;
              padding-bottom: 30px !important;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
        }
      }
      .text {
        margin-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .footer {
      width: calc(100% - 20px);
      margin: auto;
      position: static;
    }
  }
}

.noStory {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c4c4c4;
}

@media only screen and (max-width: 460px) {
  .StoryPopup {
    .content {
      .header {
        .heading {
          .author {
            .authorPhoto {
              left: calc(50% - 50px);
              width: 100px !important;
              height: 100px !important;
            }
          }
        }
      }
    }
  }
}
