.MainStory {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  padding: 8px;
  padding-bottom: 0;
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 323px;
  border: 1px solid rgba(47, 66, 115, 0.25);
  border-top: none;
  cursor: pointer;

  .image {
    width: 100%;
    height: 100%;
    background-image: url(https://source.unsplash.com/random/800x600);
    background-position: center;
    background-size: cover;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px 30px 8px 26px;
    background: rgba(2, 6, 18, 0.81);
    .title {
      box-sizing: border-box;
      width: 100%;
      padding: 0;
      margin: 0;
      font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;
      color: #c4c4c4;
    }
    .author {
      display: flex;
      flex-direction: row-reverse;
      box-sizing: border-box;
      width: 100%;
      font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      margin-top: 8px;
      align-items: center;
      text-align: center;
      color: #2f4273;
    }
  }
}
