.NeighborhoodsList {
  display: flex;
  position: fixed;
  z-index: 2;
  width: 50%;
  height: 30px;
  padding-top: 5px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  overflow: hidden;

  &.expand {
    height: 100%;
    overflow-y: auto;
  }

  .overlay {
    display: none;
    position: fixed;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 1, 2, 0.54);
    &.visible {
      display: block;
    }
  }

  .neighborhoodButton {
    height: 25px;
    box-sizing: border-box;
    margin: 0;
    margin-right: 6px;
    margin-bottom: 11px;
    padding: 0 12px 1px 12px;
    //padding-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #485caf;
    background: rgba(1, 5, 14, 0.79);
    border: 1px solid rgba(47, 66, 115, 0.5);
    border-radius: 15px;
    cursor: pointer;
    pointer-events: auto;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;

    &:hover,
    &.active {
      background-color: rgba(72, 99, 170, 0.69);
      color: #050812;
    }
    &.ellipsisButton {
      position: absolute;
      right: 15px;
      margin-right: 0;
    }
    &.allButton {
      margin-left: 15px;
    }
  }
  .neighborhoodsButtonsContainer {
    padding-right: 58px;
  }
}

@media only screen and (max-width: 1024px) {
  .NeighborhoodsList {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    .overlay {
      width: 100%;
    }
    .neighborhoodButton {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      &:hover {
        color: #485caf;
        background: rgba(1, 5, 14, 0.79);
      }
      &.allButton {
        margin-left: 0;
      }
      &.ellipsisButton {
        right: 12px;
      }
    }
    .neighborhoodsButtonsContainer {
      justify-content: space-between;
      padding-left: 12px;
      padding-right: 55px;
    }
  }
}
