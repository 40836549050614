.HomeContent {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 325px;
  &.noAnimations * {
    transition: none;
  }
}

.menu {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-top: 22px;

  ul {
    position: relative;
    width: 460px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #2f4273;
    text-transform: uppercase;
    list-style: none;
    cursor: pointer;

    li:hover {
      span {
        color: #c4c4c4;
      }
    }

    li.active {
      span {
        color: #c4c4c4;
      }
    }

    li.line {
      position: absolute;
      width: 100%;
      bottom: 0;
      border-bottom: 2px solid #9797aa;
      transition: all 0.3s ease-in-out;

      &.recent {
        left: 30px;
        width: 105px;
      }

      &.featured {
        left: 205px;
        width: 63px;
      }

      &.communities {
        left: 335px;
        width: 99px;
      }
    }
  }

  .arrowIcons {
    position: absolute;
    right: 50px;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 65px;
    height: 12px;
    font-size: 0;
    cursor: pointer;
    i {
      display: block;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .switchPrevious {
      background-image: url(../../../assets/images/switchPrevious.svg);
      &.disabled {
        background-image: url(../../../assets/images/switchPreviousDisabled.svg);
        cursor: auto;
        pointer-events: none;
      }
    }

    .switchNext {
      background-image: url(../../../assets/images/switchNext.svg);
      &.disabled {
        background-image: url(../../../assets/images/switchNextDisabled.svg);
        cursor: auto;
        pointer-events: none;
      }
    }
  }
}

.content {
  height: 100%;
  border-top: 1px solid rgba(47, 66, 115, 0.25);
  background-color: black;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

@media only screen and (max-width: 1024px) {
  .HomeContent {
    display: none;
  }
}

.container {
  width: 100%;
}

.borderAnimation {
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  box-sizing: border-box;
  --animation-speed: 0.5s;
  --color: #c4c4c4;
  color: var(--color);

  position: relative;
  display: inline-block;

  .borderAnimationInner {
    color: #2f4273;
    padding: 12px 12px 11px 11px;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;

    z-index: 1;
  }
}

@keyframes beforeBorders {
  0% {
    top: calc(var(--border-width) * -1);
    left: 50%;
    bottom: auto;
    right: auto;
    width: 0;
    height: var(--border-width);
  }
  33% {
    top: calc(var(--border-width) * -1);
    left: calc(var(--border-width) * -1);
    bottom: auto;
    right: auto;
    width: calc(var(--border-width) + 50%);
    height: var(--border-width);
  }
  66% {
    top: calc(var(--border-width) * -1);
    left: calc(var(--border-width) * -1);
    bottom: auto;
    right: auto;
    width: var(--border-width);
    height: calc((var(--border-width) * 2) + 100%);
  }
  100% {
    top: auto;
    left: calc(var(--border-width) * -1);
    bottom: calc(var(--border-width) * -1);
    right: auto;
    width: calc(var(--border-width) + 50%);
    height: calc((var(--border-width) * 2) + 100%);
  }
}

@keyframes afterBorders {
  0% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: 50%;
    width: 0;
    height: var(--border-width);
  }
  33% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: calc(var(--border-width) * -1);
    width: calc(var(--border-width) + 50%);
    height: var(--border-width);
  }
  66% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: calc(var(--border-width) * -1);
    width: var(--border-width);
    height: calc((var(--border-width) * 2) + 100%);
  }
  100% {
    top: auto;
    left: auto;
    bottom: calc(var(--border-width) * -1);
    right: calc(var(--border-width) * -1);
    width: calc(var(--border-width) + 50%);
    height: calc((var(--border-width) * 2) + 100%);
  }
}

@keyframes borderColors {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  33% {
    border-top-color: var(--color);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  66% {
    border-top-color: var(--color);
    border-right-color: var(--color);
    border-bottom-color: transparent;
    border-left-color: var(--color);
  }
  100% {
    border-top-color: var(--color);
    border-right-color: var(--color);
    border-bottom-color: var(--color);
    border-left-color: var(--color);
  }
}

@keyframes background {
  to {
    background: #131929;
    text-shadow: 0 0.1em 0.1em #111;
  }
}

@media (min-width: 850px) {
  body {
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .border-animation {
    font-size: 6em;
    line-height: 1em;
  }
}
