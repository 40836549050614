.Communities {
  display: flex;
  height: 250px;
  padding-left: 10px;
  position: absolute;
  z-index: 0;
  opacity: 0;
  background-color: black;
  &.visible {
    opacity: 1;
  }
  transition: left 0.5s ease-out, opacity 0.5s ease-in-out;
}

.spinner {
  position: relative;
  left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
