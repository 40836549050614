.RecentStories {
  display: flex;
  height: 250px;
  padding-left: 10px;
  opacity: 0;
  position: absolute;
  z-index: 0;
  &.visible {
    opacity: 1;
  }
  transition: left 0.5s ease-out, opacity 0.5s ease-in-out;

  &.noAnimations {
    transition: none;
  }
}

.searchItem {
  min-width: 305px;
  max-width: 305px;
  height: 250px;
  margin-right: 15px;
}

.spinner {
  position: relative;
  left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;

  &.onlyOneStory {
    width: 99vw;
  }
}
