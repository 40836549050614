.HomePage {
  //height: 100vh;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #02050c;
}

/* html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
 */
