.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1;
  top: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 56px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  background-color: #02050c;
  border-bottom: 1px solid rgba(47, 66, 115, 0.25);

  .logoLink {
    text-decoration: none;
    h1 {
      font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0;
      text-align: center;
      color: #c4c4c4;

      .capitalLetter {
        font-size: 27px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }
}

.burgerMenu,
.search {
  width: 34px;
  height: 100%;
  padding: 0;
  padding-left: 23px;
  padding-right: 23px;
  margin: 0;
  font-size: 0;
  background-image: url(../../assets/images/burger.svg);
  background-repeat: no-repeat;
  background-position: center 18px;
  cursor: pointer;
}

.search {
  width: 23px;
  padding-left: 17px;
  padding-right: 17px;
  background-image: url(../../assets/images/search.svg);
}

.hidden {
  visibility: hidden;
}

@media only screen and (max-width: 1024px) {
  .Header {
    z-index: 1;

    &.mobileSearchIsOpen {
      z-index: 3;
    }
  }
}

@media only screen and (max-width: 667px) {
  .Header {
    .logoLink {
      h1 {
        font-size: 16px;
        line-height: 20px;

        .capitalLetter {
          font-size: 22px;
          line-height: 27px;
        }
      }
    }
  }

  .burgerMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .search {
    padding-left: 11px;
    padding-right: 11px;
  }
}
