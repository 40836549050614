.StoriesPopup {
  position: fixed;
  z-index: 3;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background-color: #03091a;

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 47px;

    .closeIcon {
      position: absolute;
      left: 11px;
      display: block;
      width: 30px;
      height: 28px;
      font-size: 0;
      background-image: url(../../../../../assets/images/storiesPopupCloseIcon.svg);
      cursor: pointer;
    }

    h1 {
      margin: 0;
      padding: 0;
      font-family: Libre Baskerville, Times New Roman, Georgia, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-align: center;
      color: #c4c4c4;
      text-transform: uppercase;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    overflow: auto;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
