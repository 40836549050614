.MobileSearch {
  position: fixed;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  background-color: #02040a;
  border-radius: 10px 0px 0px 10px;
  transition: right 0.1s ease-out;
  overflow: auto;

  &.visible {
    right: 0;
  }

  .searchHeader {
    display: flex;
    position: sticky !important;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    height: 90px;
    padding-left: 60px;
    padding-right: 17px;
    background-color: #02040a;

    .closeIcon {
      position: absolute;
      left: 22px;
      display: block;
      width: 30px;
      height: 30px;
      font-size: 0;
      background-image: url(../../assets/images/storiesPopupCloseIcon.svg);
      cursor: pointer;
    }
  }

  .searchContent {
    display: flex;
    flex-wrap: wrap;
  }
}

.searchForm {
  display: flex;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  height: 34px;
  border: 1px solid #32436f;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #2f4273;

  input {
    display: block;
    background: #050e26;
    width: 60%;
    height: 100%;
    padding-left: 15px;
    box-sizing: border-box;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #c4c4c4;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &::placeholder {
      font-size: 12px;
      opacity: 1;
      color: #2f4273;
    }
  }
  .select {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 40%;
    appearance: none;
    background: #050e26;
    box-sizing: border-box;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
    background-image: url(../../assets/images/searchArrow.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 5%;
    border: none;
    border-left: 1px solid #32436f;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    .options {
      display: block;
      position: fixed;
      z-index: -2;
      max-height: calc(100% - 70px);
      top: 23px;
      left: 60px;
      right: 17px;
      box-sizing: border-box;
      padding: 0;
      padding-bottom: 7px;
      margin: 0;
      margin-top: 44px;
      list-style: none;
      background-color: rgba(50, 67, 111, 0.95);
      border: 1px solid #32436f;
      font-size: 16px;
      color: #c4c4c4;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
      overflow: auto;

      &.show {
        display: block;
      }

      li {
        width: 100%;
        text-transform: capitalize;
        cursor: pointer;

        span {
          display: block;
          width: 100%;
          padding-top: 7px;
          padding-bottom: 7px;
        }

        &.expandable > span {
          background-image: url(../../assets/images/searchArrow.svg);
          background-repeat: no-repeat;
          background-position: 95% 50%;
          background-size: 5%;
        }

        &:hover > span {
          background-color: #152141;
        }
      }
    }
  }
}

.paddingBox {
  width: 100%;
  height: 44px;
  background-color: red;
}

.communitiesList,
.neighborhoodsList {
  display: none;
  position: relative;
  list-style: none;
  margin: 0;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 0;
  border-top: 1px gray solid;
  border-bottom: 1px gray solid;

  &.expand {
    display: block;
  }
}

.closeButton {
  position: absolute;
  left: -38px;
  display: block;
  width: 33px;
  height: 100%;
  font-size: 0;
  background-image: url(../../assets/images/closeIcon.svg);
  background-size: cover;
  background-position: center;
  background-color: #02050c;
  cursor: pointer;
}

.searchButton {
  position: absolute;
  top: 4px;
  right: -38px;
  display: block;
  width: 33px;
  height: 100%;
  font-size: 0;
  background-image: url(../../assets/images/search.svg);
  background-size: cover;
  background-position: center;
  background-color: #02050c;
  cursor: pointer;
}

.spinner,
.nothingFound,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  p {
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #c4c4c4;
  }
}
