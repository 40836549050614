.Community {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 305px;
  padding-top: 13px;
  padding-left: 10px;
  padding-right: 10px;
  height: 250px;
  margin-right: 15px;
  text-decoration: none;
  cursor: pointer;

  &.mobile {
    width: 100%;
    height: 200px;
    margin: 0;
    padding-top: 0;
    .image {
      width: 100%;
      min-height: 134px;
    }
    .heading {
      background-color: #03091a;
    }
  }

  &:hover {
    background-color: #131929;
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 77px;
  }

  h2 {
    vertical-align: middle;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    color: #c4c4c4;
  }
}

.image {
  width: 285px;
  height: 160px;
  background-image: url(https://source.unsplash.com/random/800x600);
  background-size: cover;
  background-position: center;
  border-radius: 2px;
}
