.Search {
  display: flex;
  position: absolute;
  z-index: 1;
  max-width: 100%;
  right: 55px;
  left: 55px;
  box-sizing: border-box;
  height: 34px;
  border: 1px solid rgba(47, 66, 115, 0.5);
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  input {
    width: 100%;
    display: block;
    background: #050e26;
    height: 100%;
    padding-left: 15px;
    box-sizing: border-box;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 16px;
    color: #c4c4c4;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &::placeholder {
      color: #2F4273;
      font-size: 16px;
      opacity: 1;
    }
  }
  .select {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: 300px;
    appearance: none;
    background: #050e26;
    box-sizing: border-box;
    font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
    font-size: 16px;
    color: #2F4273;
    text-align: center;
    text-transform: capitalize;
    background-image: url(../../assets/images/searchArrow.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 5%;
    border: none;
    border-left: 1px solid rgba(47, 66, 115, 0.5);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;

    .options {
      display: block;
      position: fixed;
      z-index: -2;
      min-width: 300px;
      max-height: calc(100% - 44px);
      top: 0;
      box-sizing: border-box;
      padding: 0;
      padding-bottom: 7px;
      margin: 0;
      margin-top: 44px;
      color: #c4c4c4;
      list-style: none;
      background-color: rgba(50, 67, 111, 0.7);
      border: 1px solid rgba(47, 66, 115, 0.5);
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
      overflow: auto;

      &.show {
        display: block;
      }

      li {
        width: 100%;
        text-transform: capitalize;
        cursor: pointer;

        span {
          display: block;
          width: 100%;
          padding-top: 7px;
          padding-bottom: 7px;
        }

        &.expandable > span {
          background-image: url(../../assets/images/searchArrow.svg);
          background-repeat: no-repeat;
          background-position: 95% 50%;
          background-size: 5%;
        }

        &:hover > span {
          background-color: #152141;
        }
      }
    }
  }
}

.paddingBox {
  width: 100%;
  height: 44px;
  background-color: red;
}

.communitiesList,
.neighborhoodsList {
  display: none;
  position: relative;
  list-style: none;
  margin: 0;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 0;
  border-top: 1px gray solid;
  border-bottom: 1px gray solid;

  &.expand {
    display: block;
  }
}

.closeButton {
  position: absolute;
  left: -38px;
  display: block;
  width: 33px;
  height: 100%;
  font-size: 0;
  background-image: url(../../assets/images/closeIcon.svg);
  background-size: cover;
  background-position: center;
  background-color: #02050c;
  cursor: pointer;
}

.searchButton {
  position: absolute;
  top: 4px;
  right: -38px;
  display: block;
  width: 23px;
  height: 100%;
  font-size: 0;
  background-image: url(../../assets/images/search.svg);
  background-size: cover;
  background-position: center;
  background-color: #02050c;
  cursor: pointer;
}
