.SideMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 402px;
  padding-left: 64px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  background-color: #02040a;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: left 0.1s ease-out;
  overflow-x: auto;

  header {
    display: flex;
    box-sizing: border-box;
    height: 62px;
    margin-bottom: 45px;
    padding-top: 35px;
  }

  h2 {
    width: 100%;
    position: relative;
    left: -32px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #7284b6;
  }

  &.show {
    left: 0;
  }

  &.hide {
    left: -753px;
  }
}

.col {
  width: 254px;
  &:last-child {
    width: 300px;
    display: block;
    &.show {
      display: block;
    }
  }
}

.closeButton {
  position: relative;
  left: -44px;
  display: block;
  width: 32px;
  height: 27px;
  font-size: 0;
  background-image: url(../../assets/images/closeIcon.svg);
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.neighborhoodListContainer {
  top: 210px;
  width: 300px;
  bottom: 0;
  background-color: #02040a;
}

.neighborhoodsList {
  display: block;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  padding-top: 17px;
  flex-direction: column;
  list-style: none;
}

.menu .neighborhoodsList {
  border-bottom: none;
}

.neighborhoodLi {
  margin-bottom: 13px;
}

.menu {
  .neighborhoodLi {
    padding-top: 27px;
    width: 254px;
    padding-bottom: 36px;
    border-bottom: 1px solid rgba(47, 66, 115, 0.5);

    &:first-child {
      .neighborhoodLi {
        border: none;
        padding-top: 0;
        padding-bottom: 0;

        &:first-child {
          padding-top: 10px;
          border-top: 1px solid rgba(47, 66, 115, 0.5);
        }
        &:last-child {
          margin-bottom: -20px;
        }
      }
    }

    &:nth-child(2) {
      height: 20px;
      overflow: hidden;
      transition: height 0.1s ease-out;

      .neighborhoodLink {
        //display: block;
        margin-bottom: 30px;
        border: none;
      }

      &.expand {
        height: 305px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.neighborhoodLink {
  text-decoration: none;
  color: #6b7cab;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
  white-space: pre-wrap;
}

.capitalLetter {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.menuFooter {
  width: 276px;
  height: 50px;
  padding-bottom: 42px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #6b7cab;

  p {
    padding: 0;
    margin: 0;

    &:last-child {
      letter-spacing: 0.1em;
    }
  }
}

.submenuButton,
.newsLetter {
  display: flex;
  align-items: center;

  &:hover {
    .neighborhoodLink {
      text-decoration: underline;
    }

    .arrowIcon {
      transform: scale(1.2);

      &.clicked {
        transform: scale(1.2) rotate(90deg) translate(7px);
      }
    }
  }
}

.arrowIcon {
  display: block;
  width: 17px;
  height: 17px;
  margin-left: 17px;
  font-size: 0;
  background-image: url(../../assets/images/arrowSubmenu.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.1s ease-out;

  &.clicked {
    transform: rotate(90deg) translate(7px);
    margin-bottom: 10px;
  }
}
