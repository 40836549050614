.Stories {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 56px;
  right: 0;
  bottom: 313px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: #00030b !important;
  border-left: 1px solid rgba(47, 66, 115, 0.25);
  border-bottom: 1px solid rgba(47, 66, 115, 0.25);
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.nothingFound {
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #c4c4c4;
}

@media only screen and (max-width: 1024px) {
  .Stories {
    position: fixed;
    top: 45%;
    bottom: 53px;
    width: 100%;
    &.visible {
      z-index: 2;
    }

    background: linear-gradient(
      180deg,
      rgba(1, 3, 9, 0.38) 0%,
      rgba(1, 3, 9, 0) 100%
    );
  }
}
