.ErrorMessage {
  align-self: center;
  margin: auto;
  //padding-bottom: 48px;
  font-family: 'Libre Baskerville', 'Times New Roman', Georgia, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #c4c4c4;
}
